"use client";
import Image from "next/image";
import Link from "next/link";
import TitleImage from "./components/Image";

const MainPage: React.FC = () => {
  
    console.log()
  return (
    <div className="max-w-10xl mx-auto bg-white p-6">
      
      {/* 상단 컨텐츠 */}
      <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg relative">
        {/* 브랜드 로고 */}
        <div className="flex flex-wrap justify-center gap-4 py-4 z-10">
          {[
            { src: "/contents/image/kepco.png", alt: "KEPCO" },
            { src: "/contents/image/khnp.png", alt: "KHNP" },
            { src: "/contents/image/koreaExpressway.png", alt: "Korea Expressway" },
            { src: "/contents/image/koreaBusan.png", alt: "Korea Busan" },
            { src: "/contents/image/koreaLoadLand.png", alt: "Korea Load Land" },
          ].map((item, index) => (
            <div key={index} className="flex-shrink-0 w-24 sm:w-32 lg:w-40 h-24 sm:h-32 lg:h-32 flex items-center justify-center">
              <Image
                src={item.src}
                alt={item.alt}
                width={150}
                height={100}
                className="object-contain"
              />
            </div>
          ))}
        </div>

        {/* 텍스트 섹션 */}
        <div className="text-center mt-16 mb-8">
          <div className="text-xl sm:text-2xl mb-3 font-semibold text-blue-600">SOLUTION</div>
          <h2 className="text-3xl sm:text-4xl font-semibold">아이비즈만의 대표 솔루션</h2>
          <div className="flex justify-center mt-4 mb-10">
            <div className="border-b-2 border-blue-600 w-6 sm:w-10 mx-2"></div>
            <div className="border-b-2 border-blue-600 w-1 sm:w-2"></div>
          </div>
        </div>

        {/* 박스 컨테이너 */}
        <div className="flex flex-wrap justify-center gap-4 mb-40 relative z-10">
          {[
            { 
              href: "solution/utm/video",
              title: "UTM",
              description: "보안/재난 방지 분야의 최고의 솔루션",
              icon: "flaticon-utm"
            },
            { 
              href: "/solution/its/turnnel",
              title: "ITS",
              description: "도로 교통 분야의 노하우가 담긴 솔루션",
              icon: "flaticon-its"
            },
            { 
              href: "/solution/lms",
              title: "LMS",
              description: "LMS 분야의 전세계적인 솔루션 Blackboard",
              icon: "flaticon-lms"
            },
          ].map((item, index) => (
            <div key={index} className="relative w-full sm:w-80 lg:w-80 h-80 bg-white border border-gray-300 rounded-lg overflow-hidden group transition-all duration-300">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-500 transition-transform duration-1000 transform -translate-y-full group-hover:translate-y-0"></div>
              <Image
                src="/contents/image/mainShape.png"
                className="w-1/4 sm:w-1/3 h-1/4 sm:h-1/3 object-cover absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                alt="Environment Diagram"
                width={300}
                height={200}
              />
              <div className="absolute inset-0 flex items-center justify-center text-black text-lg font-semibold p-4 sm:p-6 pt-24 sm:pt-28 z-30">
                <div className={`${item.icon} absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl sm:text-4xl`}></div>
                <div className="text-center">
                  {item.title}<br /> {item.description}
                </div>
                <Link href={item.href}>
                  <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 cursor-pointer">
                    <div className="w-10 sm:w-12 h-10 sm:h-12 border-2 border-gray-300 rounded-full flex items-center justify-center shadow-lg">
                      <div className="xl-dropDownMenu-after mr-2"></div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* 테두리 색상 애니메이션 */}
              <div className="absolute inset-0 border-4 border-transparent group-hover:border-blue-600 transition-all duration-300"></div>
            </div>
          ))}
        </div>

        {/* 타이틀 이미지와 flaticon 텍스트 섹션 */}
        <div className="flex flex-col lg:flex-row items-center lg:items-start relative z-10">
          <div className="flex-1 pr-4">
            <TitleImage 
            image1="/contents/image/shape3.png"
            image2="/contents/image/shape.png"
            image3="/contents/image/shape2.png"
            slides={[
              { src: "/contents/image/shape2.png", alt: "Blackboard Collaborate" },
              { src: "/contents/image/shape3.png", alt: "Blackboard Collaborate" },
              { src: "/contents/image/trust.png", alt: "Blackboard Collaborate" },
              { src: "/contents/image/turnOn.png", alt: "Blackboard Collaborate" }
            ]}
            />
          </div>
          <div className="flex-1 mt-8 lg:mt-0 lg:ml-20">
            <div className="text-2xl sm:text-4xl font-semibold">방송, 통신, 보안, 방재교육 분야</div>
            <p className="mt-4 text-base sm:text-lg">많은 경험을 통해 고객에 맞춤형 솔루션을 제시하여 드립니다.</p>
            <div className="mt-8">
              <div className="flex items-center space-x-2 mb-2">
                <div className="text-3xl sm:text-4xl flaticon-company"></div>
                <div className="text-lg sm:text-xl font-semibold">신뢰가능한 기업</div>
              </div>
              <p className="text-gray-600 text-sm sm:text-base">15년 이상의 업력으로 신뢰할 수 있는 기업</p>
            </div>
            <div className="mt-4">
              <div className="flex items-center space-x-2 mb-2">
                <div className="text-3xl sm:text-4xl flaticon-solution"></div>
                <div className="text-lg sm:text-xl font-semibold">인증 솔루션</div>
              </div>
              <p className="text-gray-600 text-sm sm:text-base">인증받은 솔루션으로 안정적인 운영이 가능합니다.</p>
            </div>
            <div className="flex items-center mt-7">
              <Image
                src="/contents/image/sign.png"
                alt="Korea Load Land"
                width={150}
                height={100}
                className="object-contain"
              />
              <div className="ml-4 border-l-2 border-gray-300 pl-4">
                <div className="text-sm sm:text-base font-semibold">김수도</div>
                <div className="text-sm sm:text-base">대표이사</div>
              </div>
            </div>
          </div>
        </div>

        {/* Blackboard 제품군 플랫폼의 진정한 가치 */}
        <div className="text-center mt-72 mb-2 relative z-10">
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Blackboard 제품군 플랫폼의 진정한 가치
          </h2>
          <div className="flex justify-center mt-4 mb-10">
            <div className="border-b-2 border-blue-600 w-6 sm:w-10 mx-2"></div>
            <div className="border-b-2 border-blue-600 w-1 sm:w-2"></div>
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-4 mb-10 relative z-10">
          {[
            {
              icon: "flaticon-select",
              title: "솔루션선택",
              description: "고객에 필요한 솔루션 선택 및 미팅 이전 요구사항 정보 수집",
            },
            {
              icon: "flaticon-meeting",
              title: "미팅진행",
              description: "고객의 요구사항을 파악. 적절한 솔루션 제안",
            },
            {
              icon: "flaticon-strategy",
              title: "계약 및 사업관리",
              description: "계약 진행 후 담당 PM지정을 통하여 설치 완료와 초기 안정화 단계까지 지원",
            },
            {
              icon: "flaticon-helpme",
              title: "유지보수",
              description: "유지보수 및 담당자 지정을 통해 지속적인 유지보수 제공",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="w-full sm:w-64 p-4 border rounded-lg mx-3 h-64 sm:h-80 bg-white border-gray-300 relative z-20 transition-all duration-300 group"
            > 
              <div
                className={`${item.icon} mb-4 text-3xl sm:text-4xl text-gray-600`}
                style={{ lineHeight: '1.5' }}
              ></div>
              <div className="font-semibold text-base sm:text-lg">{item.title}</div>
              <p className="text-gray-600 mt-2 text-sm sm:text-base">{item.description}</p>
              {/* 테두리 색상 애니메이션 */}
              <div className="absolute inset-0 border-2 border-transparent group-hover:border-blue-600 transition-all duration-300"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
