'use client';
import { useState } from 'react';
import Image from 'next/image';

interface TitleImageProps {
  image1: string
  image2: string
  image3: string
  slides: {
    src:string
    alt:string
  }[]
}



const TitleImage: React.FC<TitleImageProps> = ({image1, image2, image3, slides}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className='relative w-full h-80'>
      {/* 화면 크기가 2xl 이하일 때 보이는 슬라이더 */}
      <div className='block 2xl:hidden'>
        <div className='relative overflow-hidden'>
          <div className='flex transition-transform duration-800 ease-in-out'
               style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
            {slides.map((slide, index) => (
              <div key={index} className='flex-shrink-0 w-full h-full'>
                <Image
                  src={slide.src}
                  alt={slide.alt}
                  layout="responsive"
                  width={400}
                  height={400}
                  className="object-cover rounded-lg"
                />
              </div>
            ))}
          </div>
          <div className='absolute inset-0 flex justify-between items-center p-2'>
            <button className='bg-gray-800 text-white p-2 rounded-full' onClick={() => setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1))}>❮</button>
            <button className='bg-gray-800 text-white p-2 rounded-full' onClick={() => setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1))}>❯</button>
          </div>
          <div id="bullets" className='absolute bottom-2 w-full flex justify-center space-x-2 pb-2'>
            {slides.map((_, index) => (
              <span
                key={index}
                className={`w-3 h-3 rounded-full ${currentSlide === index ? 'bg-gray-800' : 'bg-gray-300'}`}
              ></span>
            ))}
          </div>
        </div>
      </div>

      {/* 화면 크기가 2xl 이상일 때 보이는 이미지 컨테이너 */}
      <div className='hidden 2xl:block'>
        <Image
          src={image1}
          className="absolute object-cover rounded-lg shadow-md border-4 border-white"
          alt="Blackboard Collaborate"
          width={400}
          height={400}
          style={{
            top: '50%',
            left: '10%',
            zIndex: 3
          }}
        />
        <Image
          src={image2}
          className="absolute object-cover rounded-lg"
          alt="Blackboard Collaborate"
          width={800}
          height={400}
          style={{
            right: '70%',
            zIndex: 1
          }}
        />
        <Image
          src={image3}
          className="absolute object-cover rounded-lg shadow-md"
          alt="Blackboard Collaborate"
          width={400}
          height={400}
          style={{
            bottom: '15%',
            right: '40%',
            zIndex: 2
          }}
        />
        <Image
          src="/contents/image/nohow.png"
          className="absolute object-cover rounded-lg shadow-md"
          alt="Blackboard Collaborate"
          width={300}
          height={300}
          style={{
            bottom: '10%',
            left: '40%',
            zIndex: 5
          }}
        />
      </div>
    </div>
  );
}


export default TitleImage;
