'use client';

import HomeBanner from './components/homeBanner';
import MainPage from './main';


const Home: React.FC = () => {
  return (
    <div>
      <HomeBanner/>
      <MainPage/>
    </div>
  );
};

export default Home;

