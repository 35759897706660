const HomeBanner = () => {
  return (
    <div className="
      block relative h-screen 
      bg-center bg-cover bg-banner 
      overflow-hidden  
      banner-after
      mx-0
      -z-10
      max-md:text-center max-md:h-auto
    ">
      <div className="h-full">
        <div className='
          block h-full w-full 
          p-0 pl-15px pr-15px m-0 ml-auto mr-auto 
          2xl:max-w-2xl
          lg:max-w-lg 
          md:max-w-md 
          sm:max-w-sm
        '>
          <div className='
            flex flex-wrap h-full
            -ml-15px -mr-15px 
            items-center
          '>
            <div className='
              block flex-none relative w-full h-auto 
              m-0 pl-15px pr-15px
              lg:max-w-1/2   
            '>
              <div className='
                block relative h-auto 
                max-md:px-0 max-md:py-60px
                z-10
              '>
                <h2 className='
                  block visible 
                  mb-25px
                  animate-fadeInDown 
                  text-6xl leading-tight text-white 
                  max-md:text-4xl
                  whitespace-nowrap // 기본적으로 줄바꿈을 방지
                  max-md:whitespace-normal // 작은 화면에서는 줄바꿈 허용
                  overflow-visible // 오버플로우가 보이도록 설정
                '>
                  Vision AI with DATA <br></br>Creating New Innovations

                  <strong className='
                    block text-white
                  '>
                    UTM ITS LMS
                  </strong>
                </h2>
                <a className='
                  block visible 
                  mb-4 
                  animate-fadeInLeft 
                  font-normal text-lg md:text-xl lg:text-2xl xl:text-3xl leading-banner-p text-white
                  whitespace-nowrap // 기본적으로 줄바꿈을 방지
                  max-md:whitespace-normal // 작은 화면에서는 줄바꿈 허용

                '>
                  비전 인공지능과 데이터로 혁신적인 세상을 만들어갑니다
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomeBanner
